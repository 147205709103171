import {
  endSoftNav,
  hasSoftNavFailure,
  inSoftNavigation,
  setSoftNavFailReason,
  softNavFailed,
  softNavInitial,
  softNavSucceeded,
  startSoftNav,
  SOFT_NAV_STATE
} from '../soft-nav-helpers'
import {isFeatureEnabled} from '../features'

import {getStoredShelfParamsForCurrentPage} from '../notifications/v2/notification-shelf-referrer-params'

interface ProgressBar {
  setValue(n: number): void
  hide(): void
  show(): void
}
interface BrowserAdapter {
  progressBar: ProgressBar
}
interface HeadSnapshot {
  detailsByOuterHTML: {
    [outerHTML: string]: {
      tracked: boolean
      elements: Element[]
    }
  }
}

if (isFeatureEnabled('TURBO')) {
  ;(async () => {
    const {PageRenderer, session, navigator} = await import('@hotwired/turbo')
    const adapter = session.adapter as typeof session.adapter & BrowserAdapter

    function beginProgressBar() {
      adapter.progressBar.setValue(0)
      adapter.progressBar.show()
    }

    function completeProgressBar() {
      adapter.progressBar.setValue(1)
      adapter.progressBar.hide()
    }

    document.addEventListener('turbo:before-fetch-request', event => {
      const frame = event.target as Element
      if (frame?.tagName === 'TURBO-FRAME') {
        beginProgressBar()
      }

      if (frame?.tagName === 'HTML') {
        const ev = event as CustomEvent
        ev.detail.fetchOptions.headers['Turbo-Visit'] = 'true'
      }

      const params = getStoredShelfParamsForCurrentPage(event.detail.url.pathname)
      if (params) {
        const newParams = new URLSearchParams(event.detail.url.search)
        for (const [key, value] of Object.entries<string>(params)) {
          if (value) {
            newParams.set(key, value)
          }
        }
        event.detail.url.search = newParams.toString()
      }
    })

    document.addEventListener('turbo:frame-render', event => {
      const frame = event.target
      if ((frame as Element)?.tagName === 'TURBO-FRAME') {
        completeProgressBar()
      }
    })

    document.addEventListener(SOFT_NAV_STATE.START, beginProgressBar)
    document.addEventListener(SOFT_NAV_STATE.END, completeProgressBar)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const originalTrackedElementsAreIdentical = Object.getOwnPropertyDescriptor(PageRenderer.prototype, 'reloadReason')
      .get!
    Object.defineProperty(PageRenderer.prototype, 'reloadReason', {
      get() {
        const reloadReason = originalTrackedElementsAreIdentical.call(this)

        if (reloadReason.reason !== 'tracked_element_mismatch') {
          return reloadReason
        }

        const currentTracked = Object.fromEntries(getSnapshotSignatures(this.currentHeadSnapshot))
        const changedKeys = []

        for (const [key, value] of getSnapshotSignatures(this.newHeadSnapshot)) {
          if (currentTracked[key] !== value) {
            changedKeys.push(key.replace(/^x-/, '').replaceAll('-', '_'))
          }
        }

        return {
          reason: `tracked_element_mismatch-${changedKeys.join('-')}`
        }
      }
    })

    function* getSnapshotSignatures(snapshot: HeadSnapshot): IterableIterator<[string, string]> {
      for (const detail of Object.values(snapshot.detailsByOuterHTML)) {
        if (detail.tracked) {
          for (const element of detail.elements) {
            if (element instanceof HTMLMetaElement && element.getAttribute('http-equiv')) {
              yield [element.getAttribute('http-equiv') || '', element.getAttribute('content') || '']
            }
          }
        }
      }
    }

    type State = Record<string, unknown> & {
      turbo?: {restorationIdentifier: string}
    }

    function patchHistoryApi(name: 'replaceState' | 'pushState') {
      const oldHistory = history[name]

      history[name] = function (this: History, state?: State, unused?: string, url?: string | URL | null) {
        // we need to merge the state from turbo with the state given to pushState in case others are adding data to the state
        function oldHistoryWithMergedState(
          this: History,
          turboState: State,
          turboUnused: string,
          turboUrl?: string | URL | null
        ) {
          oldHistory.call(this, {...state, ...turboState}, turboUnused, turboUrl)
        }

        navigator.history.update(
          oldHistoryWithMergedState,
          new URL(url || location.href, location.href),
          state?.turbo?.restorationIdentifier
        )
      }
    }

    patchHistoryApi('replaceState')
    patchHistoryApi('pushState')
  })()

  const isHashNavigation = (currentUrl: string, targetUrl: string): boolean => {
    const current = new URL(currentUrl, window.location.origin)
    const target = new URL(targetUrl, window.location.origin)

    return (
      Boolean(target.hash) &&
      current.hash !== target.hash &&
      current.host === target.host &&
      current.pathname === target.pathname &&
      current.search === target.search
    )
  }

  document.addEventListener('turbo:click', function (event) {
    if (!(event.target instanceof HTMLElement)) return

    const el = event.target.closest('[data-turbo-frame]')
    if (el instanceof HTMLElement) {
      event.target.setAttribute('data-turbo-frame', el.getAttribute('data-turbo-frame') || '')
    }

    if (!(event instanceof CustomEvent)) return
    // https://github.com/hotwired/turbo/issues/539
    // If we are doing a hash navigation, we want to prevent Turbo from performing a visit
    // so it won't mess with focus styles.
    if (isHashNavigation(location.href, event.detail.url)) {
      event.preventDefault()
    }
  })

  document.addEventListener('turbo:before-render', event => {
    if (!(event instanceof CustomEvent)) return

    const newDocument = event.detail.newBody.ownerDocument.documentElement
    const currentDocument = document.documentElement

    for (const attr of currentDocument.attributes) {
      if (!newDocument.hasAttribute(attr.nodeName) && attr.nodeName !== 'aria-busy') {
        currentDocument.removeAttribute(attr.nodeName)
      }
    }

    for (const attr of newDocument.attributes) {
      if (currentDocument.getAttribute(attr.nodeName) !== attr.nodeValue) {
        currentDocument.setAttribute(attr.nodeName, attr.nodeValue!)
      }
    }
  })

  document.addEventListener('turbo:visit', startSoftNav)
  document.addEventListener('turbo:render', endSoftNav)
  document.addEventListener('beforeunload', endSoftNav)

  document.addEventListener('turbo:load', event => {
    const isHardLoad = Object.keys((event as CustomEvent).detail.timing).length === 0
    if (!isHardLoad) {
      softNavSucceeded()
    } else if (hasSoftNavFailure() || inSoftNavigation()) {
      softNavFailed()
    } else {
      softNavInitial()
    }
  })

  // Emulate `onbeforeunload` event handler for Turbo navigtaions
  document.addEventListener('turbo:before-visit', function (event) {
    const unloadMessage = window.onbeforeunload?.(event)
    if (unloadMessage) {
      const navigate = confirm(unloadMessage)
      if (navigate) {
        window.onbeforeunload = null
      } else {
        event.preventDefault()
      }
    }
  })

  document.addEventListener('turbo:reload', function (event) {
    if (!(event instanceof CustomEvent)) return

    setSoftNavFailReason(event.detail.reason)
  })

  // When navigating to a page with a hash param, we first load the page
  // and then emulate a click to the link with the hash. Since this counts
  // as a same-page hash navigation, Turbo will not cause a second load.
  document.addEventListener(SOFT_NAV_STATE.SUCCESS, () => {
    if (location.hash === '') return

    const a = document.createElement('a')
    a.href = `#${location.hash.slice(1)}`
    a.click()
  })
}
