// History back link
//
// Links tagged with "js-pjax-history-navigate" classname gain the behavior of popping the
// last item from the browser history stack if the link's href matches the URL of
// the last history item. This behavior is suitable for links whose purpose it so
// bring users back to the previous page.

import {getBackURL, getForwardURL} from '../../history'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {isFeatureEnabled} from '../../features'

if (isFeatureEnabled('TURBO')) {
  ;(async () => {
    // okay to call `on` outside of global scope in this case as it's called conditionally to a feature flag
    // eslint-disable-next-line delegated-events/global-on
    on('click', '.js-turbo-history-navigate', function (ev) {
      const event = ev as MouseEvent

      if (!(event.currentTarget instanceof HTMLAnchorElement)) return
      // eslint-disable-next-line no-restricted-syntax
      if (event.shiftKey || event.metaKey || event.ctrlKey || event.altKey) return

      if (event.currentTarget.href === getBackURL()) {
        event.preventDefault()
        history.back()
      } else if (event.currentTarget.href === getForwardURL()) {
        event.preventDefault()
        history.forward()
      }
    })
  })()
} else {
  // okay to call `on` outside of global scope in this case as it's called conditionally to a feature flag
  // eslint-disable-next-line delegated-events/global-on
  on('pjax:click', '.js-pjax-history-navigate', function (event) {
    if (!(event.currentTarget instanceof HTMLAnchorElement)) return
    if (event.currentTarget.href === getBackURL()) {
      history.back()
      event.detail.relatedEvent.preventDefault()
      event.preventDefault()
    } else if (event.currentTarget.href === getForwardURL()) {
      history.forward()
      event.detail.relatedEvent.preventDefault()
      event.preventDefault()
    }
  })
}
